import React, {useState, useEffect} from "react"
import { Helmet } from 'react-helmet';
import axios from "axios"
import localForage from 'localforage'
import Layout from "../components/layout"
import NewPost from "../components/Posts/NewPost"
import PostItem from "../components/Posts/PostItem"
import SEO from "../components/seo";

async function getPosts(page) {
    // const endpoint = `${process.env.GATSBY_ISTNOTYOU_GET_ALL}?page=${page}`;
    const endpoint = `${process.env.GATSBY_ISTNOTYOU_GET_ALL}`;
    const result = await axios.get(endpoint)
    const res = await result.data;
    return res;
}

const LoadingImage = props =>
    <div
        style={{height: "100vh"}}
        className="uk-width-1-1 uk-flex uk-flex-middle uk-flex-center">
        <div>
            <div
                className="uk-width-1-1 uk-height-1-1 uk-text-center uk-margin-bottom"
                data-uk-spinner />
            <p>Just a sec</p>
        </div>
    </div>

const PostDetail = ({location, ...props}) => {

    let path = location.pathname.replace(/\/$/, "").split('/')
    path = path[path.length-1]
    const [isLoading, setIsLoading] = useState(false)
    const [newPost, setNewPost] = useState({})
    const [page, setPage] = useState(1)
    const [posts, setPosts] = useState([])
    // const queryPosts = props.data.allInternalPosts.edges
    // let internalPost = queryPosts.filter(p => p.node.post_id === path)
    // internalPost = internalPost.length ? internalPost[0] : false
    const [post, setPost] = useState(false)
    const [source, setSource] = useState("internal")

    useEffect(
        () => {
            // const token = localStorage.auth
            // token && token.length ? setAuthenticated(true) : setAuthenticated(false)

            let data = []
            localForage.getItem('postData')
            .then(res => {
                if(!res) {
                    console.log("NO DATA")
                    setIsLoading(true)
                }
                data = res ? res : []
                const primePost = data.filter(d => d.id === parseInt(path))
                setPosts(data.filter(p => p.id !== parseInt(path)))
                if(!post) {
                    if(!primePost.length) throw "No record";
                    setPost(...primePost)
                    console.log("PRIME POST", ...primePost)
                    setSource("prime")
                }

                getPosts(0)
                .then(response => {
                    const posts = response.map(d => d.id)
                    const data = response.sort((a, b) => new Date(parseInt(b.data.timestamp)).getTime() - new Date(parseInt(a.data.timestamp)).getTime());
                    // const prev = response.prev;
                    // const next = response.next;
                    const primePost = data.filter(d => d.id === parseInt(path))
                    setPosts(data.filter(p => p.id !== parseInt(path)))
                    if(!post) {
                        if(!primePost.length) throw "No record";
                        setPost(...primePost)
                        // console.log("PRIME POST", ...primePost)
                        setSource("prime")
                    }
                    setIsLoading(false)
                })
                .catch(err => {
                    console.log("GET ERROR RESPONSE", err)
                    window.location.replace('/');
                })
            })
            .catch(err => {
                console.log("GET ERROR RESPONSE", err)
                window.location.replace('/');
            })
        }, []
    )


    // useEffect(
    //     () => {
    //         getPosts(0)
    //         .then(response => {
    //             const posts = response.map(d => d.id)
    //             const data = response.sort((a, b) => new Date(parseInt(b.data.timestamp)).getTime() - new Date(parseInt(a.data.timestamp)).getTime());
    //             // const prev = response.prev;
    //             // const next = response.next;
    //             const primePost = data.filter(d => d.id === parseInt(path))
    //             setPosts(data.filter(p => p.id !== parseInt(path)))
    //             if(!post) {
    //                 if(!primePost.length) throw "No record";
    //                 setPost(...primePost)
    //                 // console.log("PRIME POST", ...primePost)
    //                 setSource("prime")
    //             }
    //             setIsLoading(false)
    //         })
    //         .catch(err => {
    //             console.log("GET ERROR RESPONSE", err)
    //             window.location.replace('/');
    //         })
    //     }, []
    // )

    // console.log("WHERE'S THE POST PATH", post, source)

    if(!post) return null;
    const description = `A maskie message from ${post.data.country} on itsnotyouits.us`
    const url = `https://www.itsnotyouits.us/post/${post.id}`
    const title = `${post.data.headline} ${post.data.message}`
    const image = `${post.data.file}`

    return (
        <Layout location={location} title={"It's US"}>
            <SEO title={title} description={description} image={image} pathname={location.pathname} url={url} />
            <Helmet>
                <title>{title}</title>
                <meta name="image" content={`${image}`} />
                <meta name="url" content={`${url}`} />
                <meta name="description" content={`${description}`} />

                <meta property="og:title" content={title} />
                <meta property="og:image" content={`${image}`} />
                <meta property="og:description" content={`${description}`} />
                <meta property="og:url" content={`${url}`} />

                <meta property="twitter:title" content={title} />
                <meta property="twitter:image" content={`${image}`} />
                <meta property="twitter:description" content={`${description}`} />
            </Helmet>
            <section className="uk-section uk-padding-remove-bottom">
                <div className="uk-container uk-container-xsmall">
                    <PostItem source={source} post={post} location={location} />
                    <a
                        href="#heroMessage"
                        style={{cursor: "pointer"}}
                        className="uk-margin-top uk-margin-large-bottom uk-width-1-1 uk-text-center uk-text-danger uk-visible@m"
                        data-uk-icon="icon: arrow-down; ratio: 1.75"
                        data-uk-scroll="target: #heroMessage"
                        />
                </div>
            </section>
            <section id="heroMessage" className="uk-section uk-section-small uk-visible@m">
                <div className="uk-container uk-container-xsmall">
                    <h1>There is no USA<br />without US.</h1>
                </div>
                <div className="uk-margin-medium-top uk-container uk-container-xsmall">
                    <NewPost setNewPost={setNewPost} />
                </div>
            </section>
            <section className="uk-section uk-padding-small-top uk-margin-xlarge-bottom">
                <div className="uk-container uk-container-small">
                    <div className="uk-grid uk-child-width-1-2">
                        {posts.map((post, i) =>
                            <PostItem key={`post-item-${post.id}`} post={post} location={location} thumb={true} />
                        )}
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default PostDetail;
